<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer">
              <b-card class="manage-media-block">
                <b-card-body class="p-0">
                  <b-card-title>
                    Manage the display order of your photos & videos.
                  </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['fas', 'photo-video']" />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="8"
                        order="3"
                        order-lg="2"
                        class="text"
                      >
                        If you add more than one photo it will show in a
                        slideshow. You can drag the photos up and down to change
                        their order in the slideshow. If you added videos they
                        will always show above your photo slideshow.
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    variant="primary"
                    class="mt-2 mb-4"
                    @click="$router.push(`add-media-block`)"
                  >
                    Add Photos & Videos
                  </b-button>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>
                  <h5>Your Photos</h5>
                  <b-table
                    striped
                    hover
                    v-sortable="sortableOptions"
                    ref="table"
                    responsive
                    :fields="photoFields"
                    :items="
                      candidateModel.medias.filter(function (item) {
                        return item.mediaType == 1;
                      })
                    "
                    class="reorderable mb-5"
                  >
                    <template #cell(id)="data">
                      <b-icon
                        icon="arrows-move"
                        aria-hidden="true"
                        :data-item-id="data.item.id"
                      ></b-icon>
                    </template>
                    <template #cell(path_2)="data">
                      <b-img
                        class="thumbnail-img"
                        :src="data.item.path"
                        :data-item-id="data.item.id"
                        @click="onMediaPreview(data.item)"
                      ></b-img>
                    </template>
                    <template #cell(mediaDetail)="data">
                      <div class="media-detail">
                        <ul>
                          <li v-if="data.item.mediaType == 1">
                            <label>Filename:</label>
                            <div>
                              {{ ExtractFilename(data.item.path) }}
                            </div>
                          </li>
                          <li v-else>
                            <label>URL:</label>
                            <div>
                              {{ data.item.path }}
                            </div>
                          </li>
                          <li>
                            <label>Caption:</label>
                            <div>
                              <b-form-input v-model="data.item.caption" />
                              <!-- {{
                                data.item.caption == "null"
                                  ? ""
                                  : data.item.caption
                              }} -->
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #cell(id_2)="data">
                      <b-form-select
                        v-model="data.item.mediaAction"
                        :options="mediaOptions"
                      ></b-form-select>
                    </template>
                  </b-table>

                  <h5>Your Videos</h5>
                  <b-table
                    striped
                    hover
                    v-sortable="sortableOptions"
                    ref="table"
                    responsive
                    :fields="videoFields"
                    :items="
                      candidateModel.medias.filter(function (item) {
                        return item.mediaType == 2;
                      })
                    "
                    class="reorderable"
                  >
                    <template #cell(id)="data">
                      <b-icon
                        icon="arrows-move"
                        aria-hidden="true"
                        :data-item-id="data.item.id"
                      ></b-icon>
                    </template>
                    <template #cell(path_2)="data">
                      <div
                        class="thumbnail-video"
                        :data-item-id="data.item.id"
                        @click="onMediaPreview(data.item)"
                      >
                        <div
                          v-if="data.item.extras"
                          class="thumbnail-video extra-thumbnail"
                        >
                          <img
                            v-if="data.item.extras.type == 'tiktok'"
                            :src="data.item.extras.detail.thumbnail_url"
                            alt=""
                          />

                          <div
                            v-if="data.item.extras.type == 'instagram'"
                            class="embed-responsive embed-responsive-16by9"
                          >
                            <ig-block :src="data.item.path"></ig-block>
                          </div>
                        </div>

                        <div
                          class="embed-responsive embed-responsive-16by9"
                          v-else
                        >
                          <iframe
                            class="embed-responsive-item"
                            :src="ForceEmbedVideo(data.item.path)"
                            frameborder="0"
                          ></iframe>
                        </div>
                      </div>
                    </template>
                    <template #cell(mediaDetail)="data">
                      <div class="media-detail">
                        <ul>
                          <li>
                            <label>URL:</label>
                            <div>
                              {{ data.item.path }}
                            </div>
                          </li>
                          <li>
                            <label>Caption:</label>
                            <div>
                               <b-form-input v-model="data.item.caption" />                             
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #cell(id_2)="data">
                      <b-form-select
                        v-model="data.item.mediaAction"
                        :options="mediaOptions"
                      ></b-form-select>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onFormSubmit"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                :hide-save-button="candidateModel.medias.length == 0"
                :saving="saving"
                :invalid="false"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <candidate-media-preview
      :showMediaPreview="showMediaPreview"
      :media="media"
      @hide="
        showMediaPreview = false;
        media = {};
      "
    ></candidate-media-preview>
    <ModalThemeGuide
      theme-id="mediaBlock"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
import CandidateMediaPreview from "@/components/common/CandidateMediaPreview";
import { Sortable } from "sortablejs";
import { GetMediaType, ExtractFilename } from "@/services/helpers";
import { ForceEmbedVideo, DynamicLoadScript } from "@/services/helpers";
import IgBlock from "@/components/IgBlock";

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
  });
};

export default {
  name: "ManageMediaBlock",
  components: {
    CandidateMediaPreview,
    IgBlock,
  },
  mixins: [CandidateMixin],
  directives: {
    sortable: {
      name: "sortable",
      bind(el, binding, vnode) {
        const table = el;
        table._sortable = createSortable(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
    },
  },
  data() {
    return {
      photoFields: [
        {
          label: "",
          key: "id",
        },
        {
          label: "#",
          key: "order",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          label: "Preview",
          key: "path_2",
        },
        // {
        //   label: "Type",
        //   key: "mediaType",
        //   formatter: (val) => {
        //     return GetMediaType(val);
        //   },
        // },
        // {
        //   label: "Filename",
        //   key: "path",
        //   formatter: (val) => {
        //     return ExtractFilename(val);
        //   },
        // },
        {
          label: "Detail",
          key: "mediaDetail",
        },
        {
          label: "Action",
          key: "id_2",
        },
      ],
      videoFields: [
        {
          label: "",
          key: "id",
        },
        {
          label: "#",
          key: "order",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          label: "Preview",
          key: "path_2",
        },
        {
          label: "Detail",
          key: "mediaDetail",
        },
        {
          label: "Action",
          key: "id_2",
        },
      ],
      media: {},
      showMediaPreview: false,
      mediaOptions: [
        {
          text: "Show",
          value: 1,
        },
        {
          text: "Hide",
          value: 2,
        },
        {
          text: "Remove",
          value: 3,
        },
      ],
      sortableOptions: {
        chosenClass: "is-selected",
      },
    };
  },
  methods: {
    ExtractFilename: ExtractFilename,
    GetMediaType: GetMediaType,
    ForceEmbedVideo: ForceEmbedVideo,
    onMediaPreview(e) {
      this.showMediaPreview = true;
      this.media = e;
    },
    onPreview() {},
    onBack() {
      this.$router.push("add-media-block");
    },
    onSkip() {
      this.$router.push("social-links");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        items: this.candidateModel.medias,
      };

      this.saving = true;
      this.checkMediaTable();

      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.candidateService
            .SaveMediaTableAsync(payload)
            .then((res) => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("social-links");
                }
              }, 500);
            })
            .catch((e) => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
    checkMediaTable() {
      let table = document.querySelectorAll(".reorderable tbody tr");
      let order = 1;

      for (let i = 0; i < table.length; i++) {
        let td = table[i].querySelectorAll("td")[1].innerHTML;

        if (this.candidateModel.medias[parseInt(td - 1)]) {
          if (
            parseInt(
              this.candidateModel.medias[parseInt(td - 1)].mediaAction
            ) !== 3
          ) {
            this.candidateModel.medias[parseInt(td - 1)].order = order;
            order++;
          }
        }
      }
    },
  },
  updated() {
    DynamicLoadScript("https://www.instagram.com/embed.js");
    //Reprocess instagram so it will load dynamically
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
::v-deep .reorderable tbody {
  cursor: all-scroll;
}

.admin-wizard .manage-media-block {
  h5 {
    font-family: $montserrat;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .table {
    .thumbnail-img {
      cursor: pointer;
      width: 150px;
      height: auto;
    }

    .thumbnail-video {
      cursor: pointer;
      width: 150px;
      height: auto;

      .embed-responsive {
        cursor: none;
        pointer-events: none;
      }
    }

    .media-detail {
      min-width: 350px;
      line-height: 20px;

      label {
        font-weight: 600;
        margin-bottom: 0;
      }

      li {
        margin-bottom: 10px;
      }
    }

    .custom-select {
      font-size: 14px;
      width: 100px;
    }
  }
}

.thumbnail-video {
  max-height: 85px;
  overflow: hidden;

  .extra-thumbnail > img {
    width: 100%;
  }
}
</style>
